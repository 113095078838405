"use client";
import { GoogleAnalytics, sendGAEvent } from "@next/third-parties/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { nextDatadogRumInit } from "datadog";
import { usePathname } from "next/navigation";
import { ReactNode, useEffect } from "react";

import { CookiesProvider } from "react-cookie";
import { RecoilRoot } from "recoil";

import { IsClientProvider } from "./IsClientProvider";
import appPackage from "../../package.json";
import { settings } from "@/configs/settings";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: true,
      useErrorBoundary: true,
      staleTime: 100000000
    },
    mutations: {
      useErrorBoundary: true
    }
  }
});

nextDatadogRumInit(
  process.env.NEXT_PUBLIC_MODE === "development"
    ? {
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        packageVersion: appPackage.version
      }
    : {
        sessionSampleRate: 3,
        sessionReplaySampleRate: 20,
        packageVersion: appPackage.version
      }
);

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const path = usePathname();
  const sendPageViewEvent = () => {
    if (path) {
      sendGAEvent({
        event: "pageview",
        page_location: path
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      sendPageViewEvent();
    }, 0);
    return () => clearTimeout(timer);
  }, [path]);

  return (
    <IsClientProvider>
      <RecoilRoot>
        <CookiesProvider>
          <QueryClientProvider client={queryClient}>
            {children}
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </CookiesProvider>
      </RecoilRoot>
      <GoogleAnalytics gaId={settings.GA_ID || ""} />
    </IsClientProvider>
  );
};
